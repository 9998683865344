import React from 'react'

const ColorWheelIcon = () => {
  return (
    <svg
      viewBox='0 0 35.089847 35.160644'
      version='1.1'
      id='svg5'
      xmlns='http://www.w3.org/2000/svg'
      style={{ stroke: 'none' }}
    >
      <defs id='defs2' />
      <g id='layer1' transform='translate(-0.09921875,-0.05736084)'>
        <path
          fill='orange'
          stroke='orange'
          d='M 31.630628,19.40295 C 30.805309,25.922737 25.588516,31.009673 19.05,31.670419 v 3.547587 C 27.539172,34.531395 34.325303,27.877029 35.178215,19.40295 Z'
          id='path1399'
        />
        <path
          fill='limegreen'
          stroke='limegreen'
          d='M 0.09921875,19.40295 C 0.92907037,27.608792 7.3345123,34.144081 15.52205,35.138424 v -3.54862 C 9.2843205,30.639263 4.4357396,25.663237 3.6473226,19.40295 Z'
          id='path1397'
        />
        <path
          fill='red'
          stroke='red'
          d='M 15.52205,0.13900961 C 7.3343399,1.1333986 0.92884369,7.6689578 0.09921875,15.875 H 3.6473226 C 4.3112903,10.602884 7.8548101,6.2415931 12.68256,4.4283827 13.587441,4.0885264 14.53744,3.8381876 15.52205,3.688147 Z'
          id='path1395'
        />
        <path
          fill='deepskyblue'
          stroke='deepskyblue'
          d='M 19.05,0.05736084 V 3.5987468 C 25.594589,4.2566925 30.816607,9.3490226 31.638896,15.875 h 3.550171 C 34.336518,7.3961683 27.544265,0.73919088 19.05,0.05736084 Z'
          id='path330'
        />
      </g>
    </svg>
  )
}

export default ColorWheelIcon
